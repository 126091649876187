/* lib imports */
import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

/* url's */
import { apiUrl, rootUrl } from '../config/environment';

/* init context */
const AuthContext = createContext();

/* handle auth process */
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(localStorage.getItem('authToken'));
  const [loading, setLoading] = useState(true);
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const navigate = useNavigate();

  /* on change of token, check auth state */
  useEffect(() => {
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      axios.get(`${apiUrl}/auth/profile`)
        .then(response => {
          setUser(response.data);
          setLoading(false);
        })
        .catch(err => {
          console.error('AuthContext - Error fetching profile:', err);
          setToken(null);
          localStorage.removeItem('authToken');
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [token]);

  /* redirect to main menu when user logs in */
  useEffect(() => {
    if (user && !loading && shouldRedirect) {
      navigate('/');
      setShouldRedirect(false);
    }
  }, [user, loading, shouldRedirect, navigate]);

  /* login a registered user */
  const login = async (username, password) => {
    try {
      const response = await axios.post(`${apiUrl}/auth/login`, { username, password });
      const { token, user } = response.data;
      setToken(token);
      setUser(user);
      localStorage.setItem('authToken', token);
      setShouldRedirect(true); 
    } catch (error) {
      console.error('Login error');
      alert('Login failed');    }
  };

  /* register a new user */
  const register = async (username, password) => {

    /* check for valid username and password */
    if (!username) {
      alert('Username is required')
      return
    }

    if (!password) {
      alert('Password is required')
      return
    }

    try {
      await axios.post(`${apiUrl}/auth/register`, { username, password });
      navigate('/login'); /* redirect to login on successful register */
    } catch (error) {
      console.error('AuthContext - registration error:', error);
      alert('Registration failed: ' + (error.response?.data?.message || error.message));    }
  };

  /* logout a signed in user */
  const logout = () => {
    setToken(null);
    setUser(null);
    localStorage.removeItem('authToken');
    navigate('/login'); /* redirect to login after successful logout */
  };

  /* update color preference */
  const updateUserColorPreference = (newColorPreference) => {
    setUser((currentUser) => ({ ...currentUser, colorPreference: newColorPreference }));
  };
  
  /* visual */
  return (
    <AuthContext.Provider value={{ 
      user, 
      login, 
      register, 
      logout, 
      updateUserColorPreference, 
      loading,
      setToken
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext };
export default AuthContext;
