/* lib imports */
import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, Navigate, useLocation } from 'react-router-dom';
import 'boxicons';

/* auth components */
import Login from './components/Login';
import Register from './components/Register';
import { AuthContext } from './context/AuthContext';

/* pages */
import Dashboard from './pages/PlayDashboard';
import Multiplayer from './pages/Multiplayer';
import Play from './pages/Play';
import Profile from './pages/Profile';
import RecentGames from './pages/RecentGames';
import Create from './pages/Create';
import Leaderboard from './pages/Leaderboard';

/* style and screen effects */
import colorSets from '../src/config/colorSets';
import './style/app.css';

/* app container */
function App() { 

  /* user */
  const { user, setToken } = useContext(AuthContext);
  const [loadingAuth, setLoadingAuth] = useState(true);

  /* on user change */
  useEffect(() => {

    if (!user) {
      return
    }

    const chosenPalette = colorSets[user.colorPreference]; /* name of color set */
    if (chosenPalette) {
      document.documentElement.style.setProperty('--color-dark', chosenPalette.dark);
      document.documentElement.style.setProperty('--color-normal', chosenPalette.normal);
      document.documentElement.style.setProperty('--color-light', chosenPalette.light);
    }
  }, [user]);

  /* token change */
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const urlToken = params.get('token');
    if (urlToken) {
      /* set */
      localStorage.setItem('authToken', urlToken);
      setToken(urlToken);
      /* remove param */
      params.delete('token');
      const newUrl = `${window.location.origin}${window.location.pathname}?${params.toString()}`;
      window.history.replaceState({}, '', newUrl);
    }
    setLoadingAuth(false);
  }, [setToken]);

  /* infrastructure of the site */
  return (
    <div className="app">
      <Routes>
        <Route path="/login" element={<PublicLayout><Login /></PublicLayout>} />
        <Route path="/register" element={<PublicLayout><Register /></PublicLayout>} />
        <Route 
          path="/" 
          element={
            <ProtectedRoute loadingAuth={loadingAuth}>
              <MainLayout />
            </ProtectedRoute>
          } 
        />        
        <Route 
          path="*" 
          element={
            <ProtectedRoute loadingAuth={loadingAuth}>
              <MainLayout />
            </ProtectedRoute>
          } 
        />      
      </Routes>
    </div>
  );
}

/* ProtectedRoute component to handle protected routes */
function ProtectedRoute({ children, loadingAuth }) {
  const { user, loading } = useContext(AuthContext);

  if (loadingAuth || loading) {
    return (
      <div className="loading-container" style={{ flex: 1 }}>
        <div className="loading-message">Loading...</div>
      </div>
    );
  }

  if (!user) {
    console.log("ProtectedRoute - redirecting to /login");
    return <Navigate to="/login" />;
  }

  return children;
}

/* main menu */
function MainLayout() {
  const location = useLocation();
  const { user, loading, logout } = useContext(AuthContext);

  const showMenu = location.pathname === '/';

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (loading) {
    return <div>Loading...</div>; 
  }

  return (
    <div className="main-layout">
      {showMenu && (
        <>
          {isMobile ? (
            <div className="mobile-only">

              <div className="mobile-header">
                <div className='mobile-title'>
                  Crossword Fight
                </div>
              </div>

              <div className="mobile-choicesWrapper">
                <div className='mobile-choice'>
                  <Link to="/multiplayer">MULTIPLAYER</Link>
                </div>
                <div className='mobile-choice'>
                  <Link to="/play">PLAY</Link>
                </div>
                <div className='mobile-choice'>
                  <Link to="/create">CREATE</Link>
                </div>
                <div className='mobile-choice'>
                  <Link to="/profile">PROFILE</Link>
                </div>
                <div className='mobile-choice'>
                  <Link to="/leaderboard">LEADERBOARD</Link>
                </div>
                <div className='mobile-choice'>
                  <Link to="/recent-games">RECENT GAMES</Link>
                </div>
              </div>

            </div>
          ) : (
            <div className="desktop-only">
              <div className="header">
                <div className="logout-container">
                  <button className="logout-button" onClick={logout}>
                    <i className="bx bx-log-out-circle"></i>
                    <span>LOGOUT</span>
                  </button>
                  <span className="greeting">Hello, {user?.username}</span>
                </div>
                <div className="title">Crossword Fight</div>
              </div>

              <div className='choicesWrapper'>
                <div className='choiceContainerLeft'>
                  <div className='choice'>
                    <Link to="/multiplayer">MULTIPLAYER</Link>
                  </div>
                  <div className='choice'>
                    <Link to="/create">CREATE</Link>
                  </div>
                  <div className='choice'>
                    <Link to="/leaderboard">LEADERBOARD</Link>
                  </div>
                </div>
                <div className='choiceContainerRight'>
                  <div className='choice'>
                    <Link to="/play">PLAY</Link>
                  </div>
                  <div className='choice'>
                    <Link to="/profile">PROFILE</Link>
                  </div>
                  <div className='choice'>
                    <Link to="/recent-games">RECENT GAMES</Link>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}

      {/* routes */}
      <Routes>
        <Route path="/play" element={<Dashboard />} /> 
        <Route path="/multiplayer" element={<Multiplayer />} />
        <Route path="/play/:puzzleId" element={<Play />} /> 
        <Route path="/profile" element={<Profile />} />
        <Route path="/recent-games" element={<RecentGames />} />
        <Route path="/create" element={<Create />} />
        <Route path="/leaderboard" element={<Leaderboard />} />
        <Route path="*" element={<Navigate to="/" />} /> 
      </Routes>
    </div>
  );
}

/* public layout */
function PublicLayout({ children }) {
  return (
    <div className="public-layout">
      {children}
    </div>
  );
}

export default App;
